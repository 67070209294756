<template>
  <a-card class="mb10" :bordered="false">
    <a-button size='small' @click="()=>{$router.go(-1)}">
      <LeftOutlined />返回
    </a-button>
    <span class="ml15 card_title">签名详情</span>
  </a-card>
  <a-card :bordered="false">
    <a-descriptions :column="1">
      <a-descriptions-item label="签名名称">{{form.sign}}</a-descriptions-item>
      <a-descriptions-item label="签名来源">{{form.account}}</a-descriptions-item>
      <a-descriptions-item label="场景说明">{{form.scene_description}}</a-descriptions-item>
      <a-descriptions-item label="三证合一">
        <a-image v-for="(item,index) in form.credentials" :key="index" :src="oss+item" alt="avatar"
          style="max-width:100px" class=" m-l-10" />
      </a-descriptions-item>
      <a-descriptions-item label="授权委托书">
        <a-image :src="oss+form.power" alt="avatar" style="max-width:100px" class=" m-l-10" />
      </a-descriptions-item>
      <a-descriptions-item label="审核反馈">{{form.remark || '-'}}</a-descriptions-item>
    </a-descriptions>
  </a-card>
</template>
<script>
  import { LeftOutlined } from "@ant-design/icons-vue";
  import { ref, onMounted, inject } from "vue";
  import { message } from "ant-design-vue";
  import { $iscode } from "@/utils/app";
  import config from "@/config";
  import { getsignDetail } from "@/api/brand";
  const oss = config.oss[process.env.VUE_APP_type];
  import store from "@/store";
  export default {
    components: { LeftOutlined },
    setup(props, { emit }) {
      //pass
      const form = ref({});
      const $route = inject("$route");
      const $router = inject("$router");
      const getMore = async () => {
        const hide = message.loading("正在加载中...", 0);
        var res = await getsignDetail({ id: $route.query.id })
          .then((res) => res.data)
          .catch((error) => error);
        setTimeout(hide);
        if ($iscode(res)) {
          form.value = res.data;
        } else {
          message.error(res.msg);
        }
      };
      onMounted(() => {
        getMore();
      });
      return {
        form,
        oss,
      };
    },
  };
</script>